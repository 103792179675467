import { IDictionaries } from '@/store/root/interfaces';
import RootStore  from '@/store/root'
import moment from 'moment';

type TranslateType = keyof IDictionaries;

const typesMap: { [key in TranslateType]: string } = {
  feePeriodTypesMapped: "name",
  serviceNamesMapped: "name",
  licenseStatuses: "name",
  subjectTypes: "name",
  paymentStatuses: "description",
  agreementStatuses: "name",
  entity: "name",
};

const filters = {
    formatDate(date: string, format = "DD-MM-YYYY HH:mm:ss") {
      moment.locale('ru');
      if (!date) return "-";
      return moment(date).format(format);
    },

    dictionary(key: TranslateType) {
      return RootStore.dictionaries[key]?.['list']
    },
  
    translate(params: {
      key: TranslateType,
      id: string | number,
      field?: string
    }) {
      console.log(params)
      const notFoundString = params.field ? "" : "Неизвестно";

  
      if (!typesMap[params.key]) return notFoundString;
      console.log(typesMap[params.key])
  
      const info: { [key in string | number]: any } = RootStore.dictionaries[params.key]?.['map'];
      console.log(RootStore.dictionaries)
      console.log(info)
  
      return (
        (info && info[params.id as any] && info[params.id][params.field ? params.field : typesMap[params.key]]) ||
        notFoundString
      );
    },
  }

  export default filters