import  io  from "socket.io-client";
import config from "@/config";
import { ElNotification } from "element-plus";
import NotificationStore from "@/store/notification";
import { ENotificationTypes } from "@/store/notification/interfaces";
import { useCookies } from "vue3-cookies"; 
const { cookies } = useCookies();

export const socket = io(config.bus.host, { 
  auth: {
    token: cookies.get("accessToken"),
    service: "BILLING",
    scope: "WEB"
  },
  autoConnect: true,
  reconnection: true
});

socket.on("connect", () => {
  console.log("Socket: connect")
});

socket.on("disconnect", () => {
  console.log("Socket: disconnect")
});

socket.on("connect_error", (error) => {
  console.log("Socket: connect_error", error)
});

socket.on("getNotification", async (notification: any) => {
  await NotificationStore.getNotificationList({
    page: 1,
    limit: 10,
    isRead: false
  })
  await NotificationStore.getNotificationList({
    page: 1,
    limit: 10,
    isRead: true
  })
  NotificationStore.getNotificationList({
    page: 1,
    limit: 100,
    isRead: false,
    type: ENotificationTypes.ALERT
  })
  ElNotification({
    title: "Новое уведомление",
    message: notification.text,
    position: 'bottom-right',
    type: 'info',
  });
});
